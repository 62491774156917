/** @define Pagination */

.beevenue-Pagination {
  justify-content: space-between;
}

@media screen and (min-width: 769px), print {
  .beevenue-Pagination {
    margin-top: -0.75rem;
  }
}
