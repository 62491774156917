.beevenue-SearchCard {
  padding: 0.5rem;
}

.beevenue-Button {
  margin-right: 1rem;
}

.beevenue-Card {
  margin-bottom: 1rem;
}

.beevenue-TagsFilter {
  width: 15rem;
}

.beevenue-TagsInput-Input {
  width: 8rem;
}

.beevenue-ShowPage-Card {
  margin-top: 1em;
}

.beevenue-BatchUpload.progress {
  margin-top: 0.5rem;
}

.beevenue-TagRatings {
  display: flex;
}

.beevenue-TagRating {
  flex-basis: 4rem;
  flex-grow: 0;
  flex-shrink: 0;
}

.beevenue-AbsentTag .tag {
  background-color: #757575;
  color: #ffffff;
}

.beevenue-Tag.tag.beevenue-Tag-first {
  margin-left: 0rem;
}

.beevenue-Tag.tag {
  margin-left: 0.5rem;
}

.beevenue-RulesPage-RuleIcons a {
  padding: 0.3rem;
}

@media screen and (min-width: 1024px) {
  .beevenue-TagsTable {
    width: 33rem;
  }
}

.beevenue-TagsTable td:first-child {
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.beevenue-Spinner {
  height: 60px;
}

.beevenue-OtpLinks-OtpLink {
  color: #000;
}

.beevenue-GoogleLoginContainer {
  height: 40px;
  overflow: hidden;
}
