/** @define Masonry */

$gutter: 1.25rem;

.beevenue-Masonry {
  display: flex;
  margin-left: -$gutter;
  width: auto;
}

.beevenue-Masonry-Column {
  background-clip: padding-box;
  padding-left: $gutter;
}

.beevenue-Masonry-Item {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
  margin-bottom: $gutter;
}

.beevenue-Masonry-Item img {
  display: block;
  width: 100%;
  height: 100%;
}

.card {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.beevenue-thumb-container {
  overflow: hidden;
  display: grid;
}

.beevenue-thumb-container * {
  grid-column: 1;
  grid-row: 1;
}

.beevenue-thumb-container video {
  visibility: hidden;
}

.beevenue-thumb-container video.is-hovered {
  visibility: visible;
  margin: 0;
  top: 0;
  height: calc(100% + 1px);
  object-fit: fill;
}

.beevenue-thumb {
  transition: all 0.05s ease-in-out;
}

.beevenue-tiny-thumb {
  filter: blur(8px);
}
