/** @define Ratings */

.beevenue-Ratings-Rating {
  line-height: 1.25em;
}

.beevenue-Ratings {
  display: flex;
}

@media screen and (max-width: 768px) {
  .beevenue-Ratings {
    flex-direction: column;
  }
}

@media screen and (min-width: 769px) {
  .beevenue-Ratings {
    flex-direction: row;
  }
}
