/** @define SimilarMedia */

.beevenue-SimilarMedia {
  margin-bottom: 1rem;
  max-height: 15rem;
  display: flex;
  justify-content: space-between;
}

.beevenue-SimilarMedia-SimilarMedium {
  flex-basis: 19%;

  display: flex;
  align-content: center;
  justify-content: center;
}

.beevenue-SimilarMedia-SimilarMedium img {
  max-height: 100%;
}
