/** @define FullPageSpinner */

.beevenue-FullPageSpinner {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.beevenue-FullPageSpinner-Inner {
  // as flex child element
  flex-basis: 25%;

  // as flex parent element
  display: flex;
  flex-direction: column;
  align-items: center;
}

.beevenue-FullPageSpinner-Status--failed {
  visibility: visible;
}

.beevenue-FullPageSpinner-Status--none {
  visibility: hidden;
}
