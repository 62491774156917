/** @define TagDetail */

.beevenue-TagDetail-AliasDelete {
  margin-left: 0.25rem;
  vertical-align: baseline;
}

.beevenue-TagDetail-EditableTitle {
  display: inline-flex;
}

.beevenue-TagDetail-SearchLink {
  font-size: 80%;
  margin-left: 0.5rem;
}
