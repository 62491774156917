.beevenue-TagHistogram {
  width: 50%;

  @media screen and (max-width: $desktop) {
    width: 100%;
  }

  @media screen and (min-width: $desktop + 1px) {
    width: 80%;
  }

  @media screen and (min-width: $fullhd) {
    width: 50%;
  }
}

.beevenue-TagHistogram text {
  font-size: 125%;
}

.beevenue-TagHistogram-Bar {
  fill: $primary;
}

.beevenue-TagHistogram-Tooltip {
  position: fixed;
  background-color: #ffffff;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
